.hero img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  filter: brightness(35%);
}
.hero {
  height: auto;
}
.hero .slogan {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: normal;
  color: white;
  line-height: 41px;
  width: 100%;
  height: auto;
}

.slogan h1 {
  font-size: 30px;
}

.slogan p {
  margin-top: 30px;
  font-size: 25px;
  width: 80%;
  margin: 0 auto;
}

.hero .arrow {
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  margin: 0 auto;
  color: white;
  font-size: 60px;
  cursor: pointer;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }
  40% {
    transform: translate3d(0, 10%, 0);
  }
  100% {
    transform: translate3d(0, 20%, 0);
  }
}
.jump {
  transform-origin: 50% 50%;
  animation: jump 0.9s linear alternate infinite;
}

.shadow {
  position: absolute;
  top: 88vh;
  height: 7.4rem;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(20, 20, 20, 0) 0,
    rgba(20, 20, 20, 0.15) 15%,
    rgba(20, 20, 20, 0.35) 29%,
    rgba(20, 20, 20, 0.58) 44%,
    #141414 68%,
    #141414 100%
  );
}
