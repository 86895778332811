:root {
  --first-color: #fb9c30;
  --input-color: #80868b;
  --border-color: #dadce0;
  --body-font: "Roboto", sans-serif;
  --normal-font-size: 1rem;
  --small-font-size: 0.75rem;
}

*::before::after {
  box-sizing: border-box;
}

.l-form {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

.form {
  width: 360px;
  padding: 2rem 1rem;
}

.formDiv {
  position: relative;
  height: 48px;
  margin-bottom: 1.5rem;
}

.formInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: var(--normal-font-size);
  border: 1px solid var(--border-color);
  outline: none;
  padding: 1rem;
  background: none; 
  z-index: 1;
}

.formLabel {
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0 0.25rem;
  background-color: #fff;
  color: var(--input-color);
  font-size: var(--normal-font-size);
  transition: 0.3s;
}

.error {
  display: flex;
  justify-content: flex-start;
  padding-top: 45px;
  margin-left: 10px;
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}

.formInput:focus + .formLabel {
  top: -0.5rem;
  left: 0.8rem;
  color: black;
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

.formInput:not(:placeholder-shown).formInput:not(:focus) + .formLabel {
  top: -0.5rem;
  left: 0.8rem;
  font-size: var(--small-font-size);
  font-weight: 500;
  z-index: 10;
}

.formInput:focus {
  border: 1.5px solid var(--first-color);
}

.ui.button {
  border-radius: 0rem;
}

@media (min-width: 900px) {
  .form {
    width: 800px;
  }
}
