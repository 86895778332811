.instruction-card {
  width: 90%;
  margin: 20px auto;
}
.instruction-card p {
  color: gray;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 8px;
}
.instruction-card h1 {
  padding: 8px;
  font-size: 24px;
}

.ui.card {
  border-radius: 0;
  box-shadow: none;
}

@media (min-width: 900px) {
  .instruction-card {
    width: 58%;
  }
  .instruction-card p {
    font-size: large;
  }
  .instruction-card h1 {
    padding: 8px;
  }
}
